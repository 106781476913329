<template>
  <UnexportedBillings
    :billings="billings"
    :billing-entries="billingEntries"
    :loading="loading"
    :customer="customer"
    @billings-export-sent="$emit('refetch')"
    @billing-entries-update="$emit('refetch-billing-entries')"
  />
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { useBillings } from '@/modules/bookkeeping/composition/billing';

import UnexportedBillings from './UnexportedBillings';

export default {
  name: 'BookkeeperUnexportedBillings',
  components: {
    UnexportedBillings,
  },
  props: {
    billingEntriesLoading: { type: Boolean, default: false },
    billingEntries: { type: Array, required: true },
    customer: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const billingIds = ref([]);

    watch(
      () => props.billingEntries,
      (newBillingEntries) => {
        const ids = newBillingEntries.map(({ billingId }) => billingId);
        const isEqual = ids.length === billingIds.value.length && ids.every((id) => billingIds.value.includes(id));
        if (!isEqual) billingIds.value = ids;
      },
      { immediate: true }
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: billingIds.value,
        businessId: root.$route.params.customerId,
      }))
    );

    const loading = computed(() => props.billingEntriesLoading.value || billingsLoading.value);
    return { billings, loading };
  },
};
</script>
