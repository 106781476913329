<template>
  <el-dialog
    visible
    :show-close="false"
    custom-class="rounded complete-data-modal"
    width="400px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="title">{{ $t('bookkeeping.fileIsDownloading') }}</h2>
      </div>
    </template>
    <div>
      <span>{{ $t('bookkeeping.theExportWasSuccessful') }}</span>
    </div>
    <div>
      <span>{{ $t('bookkeeping.recordsInFile', { count: totalEntriesExported }) }}</span>
    </div>
    <div class="mt-4">
      <a href="" @click.prevent.stop="downloadFile">{{ $t('bookkeeping.downloadTheFileAgain') }}</a>
    </div>

    <template #footer>
      <div>
        <Button @click="$emit('close')">
          {{ $t('bookkeeping.closeCompleteDataModal') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';

export default {
  components: { Button },
  props: {
    totalEntriesExported: { type: Number, required: true },
  },
  setup() {},
  mounted() {
    this.downloadFile();
  },
  methods: {
    downloadFile() {
      this.$emit('download-file');
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.complete-data-modal) {
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
  }
}
</style>
