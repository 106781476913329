<template>
  <Table
    v-loading="loading"
    custom-class="bookkeeper-aggregations-table"
    :data="tableData"
    :columns="columns"
    show-index
    rounded
    border
    @row-click="handleRowClick(tableData[$event])"
  />
</template>

<script>
import { DateTime } from 'luxon';

import { Table } from '@/modules/core';

const TABLE_HEADERS = {
  BUSINESS_NAME: 'businessName',
  DOCUMENTS_COUNT: 'documentsCount',
  LAST_BATCH: 'lastBatch',
};

export default {
  name: 'BookkeeperAggregationsTable',
  components: {
    Table,
  },
  props: {
    loading: { type: Boolean, default: false },
    bookkeeperAggregations: { type: Object, required: true },
  },
  setup() {
    return {};
  },
  computed: {
    previousMonthLastDay() {
      return DateTime.local().startOf('month').minus({ day: 1 }).endOf('day').toFormat('dd.MM.yy');
    },
    columns() {
      return [
        {
          header: this.$t(`bookkeeper.bookkeeperAggregationsTable.headers.${TABLE_HEADERS.BUSINESS_NAME}`),
          key: 'customerName',
          width: '41rem',
        },
        {
          header: this.$t(`bookkeeper.bookkeeperAggregationsTable.headers.${TABLE_HEADERS.DOCUMENTS_COUNT}`, {
            date: this.previousMonthLastDay,
          }),
          key: TABLE_HEADERS.DOCUMENTS_COUNT,
          width: '10rem',
        },
        {
          header: this.$t(`bookkeeper.bookkeeperAggregationsTable.headers.${TABLE_HEADERS.LAST_BATCH}`),
          key: TABLE_HEADERS.LAST_BATCH,
          width: '9rem',
        },
      ];
    },
    tableData() {
      return Object.keys(this.bookkeeperAggregations)
        .map((customerId) => {
          const { count, lastBatch, customerName } = this.bookkeeperAggregations[customerId];
          return {
            customerName,
            documentsCount: count ?? 0,
            lastBatch: this.formatDate(lastBatch),
            customerId,
          };
        })
        .sort((a, b) => a.customerName.localeCompare(b.customerName));
    },
  },
  methods: {
    formatDate(date) {
      return date ? DateTime.fromMillis(date).toFormat('dd.MM.yy') : '-';
    },
    handleRowClick({ customerId }) {
      this.$router.push({ name: 'bookkeeperDataTransferForCustomer', params: { customerId } });
    },
  },
};
</script>
