<template>
  <BillingsExportsTable
    :billings-exports="billingsExports"
    @billings-export-row-clicked="handleBillingsExportRowClick"
  />
</template>

<script>
import BillingsExportsTable from './BillingsExportsTable';

export default {
  name: 'BookkeeperBillingsExports',
  components: {
    BillingsExportsTable,
  },
  props: {
    billingsExports: { type: Array, required: true },
  },
  methods: {
    handleBillingsExportRowClick({ id }) {
      this.$router.push({ name: 'bookkeeperBillingsExport', params: { id } });
    },
  },
};
</script>
