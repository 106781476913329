<template>
  <div v-loading="loading" class="bookkeeper-settings" @mousewheel="closeActions">
    <div class="pb-4">
      <h3 class="my-4">{{ translate('vatAccountSettings') }}</h3>
      <div class="d-flex align-items-center border rounded custom-row">
        <p class="px-4 py-2" style="width: 230px">{{ translate('vatAccount') }}</p>
        <p class="flex-grow-1 px-4 py-2">{{ vatBookkeepingAccount ? vatBookkeepingAccount.account : '-' }}</p>
        <div style="width: 40px">
          <el-dropdown class="d-flex justify-content-center" trigger="click" placement="bottom" @command="editAccount">
            <Button type="icon" class="actions-btn p-0">
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="{ type: ACCOUNT_TYPES.VAT, accountNumber: vatBookkeepingAccount?.account }">
                {{ $t('bookkeeper.settings.edit', { accountType: accountTypeTranslation(ACCOUNT_TYPES.VAT) }) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="pb-4">
      <div class="my-4 d-flex justify-content-between align-items-center">
        <h3>{{ translate('suppliersWithDefaults') }}</h3>
        <Button type="secondary" size="mini" @click="isAddAccountsModalOpen = true">
          <div class="d-flex align-items-center">
            <PlusIcon width="20" height="20" />
            <p class="mx-1 fw-bold">{{ translate('supplier') }}</p>
          </div>
        </Button>
      </div>
      <Table :columns="columns" :data="paginatedData" rounded border show-index>
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + pageSize * currentPageIndex }}
        </template>
        <template #cell-debitAccount="{ rowData: { debitAccount } }">{{ debitAccount ?? '-' }}</template>
        <template #cell-creditAccount="{ rowData: { creditAccount } }">{{ creditAccount ?? '-' }}</template>
        <template #cell-codeAccount="{ rowData: { codeAccount } }">{{ codeAccount ?? '-' }}</template>
        <template #cell-actions="{ rowIndex, rowData: { debitAccount, creditAccount, codeAccount, supplierData } }">
          <el-dropdown
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="editAccount"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button
              :id="`actions-row-${rowIndex}`"
              type="icon"
              class="actions-btn p-0"
              :class="{ active: activeRowActionsIndex === rowIndex }"
            >
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="{ type: ACCOUNT_TYPES.DEBIT, accountNumber: debitAccount, supplierData }">
                {{ $t('bookkeeper.settings.edit', { accountType: accountTypeTranslation(ACCOUNT_TYPES.DEBIT) }) }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ type: ACCOUNT_TYPES.CREDIT, accountNumber: creditAccount, supplierData }">
                {{ $t('bookkeeper.settings.edit', { accountType: accountTypeTranslation(ACCOUNT_TYPES.CREDIT) }) }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ type: ACCOUNT_TYPES.CODE, accountNumber: codeAccount, supplierData }">
                {{ $t('bookkeeper.settings.edit', { accountType: accountTypeTranslation(ACCOUNT_TYPES.CODE) }) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </Table>
    </div>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="tableData.length && tableData.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="tableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
    <EditBookkeepingAccountModal
      v-if="isEditModalOpen"
      :type="accountToUpdateType"
      :account="accountToUpdateNumber"
      :supplier="accountToUpdateSupplier"
      @confirm="accountUpdate"
      @delete="accountDelete"
      @close="closeEdit"
    />
    <AddSupplierAccountsModal
      v-if="isAddAccountsModalOpen"
      :suppliers="suppliersToAdd"
      @save="addSupplierAccounts"
      @close="isAddAccountsModalOpen = false"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { groupBy, prop } from 'ramda';

import { Table, Button } from '@/modules/core';
import { KebabIcon, PlusIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';

import { useBookkeepingAccountsData, useCustomerSuppliers } from '../compositions';
import { ACCOUNT_TYPES } from '../constants';
import EditBookkeepingAccountModal from './EditBookkeepingAccountModal';
import AddSupplierAccountsModal from './AddSupplierAccountsModal';
import { billingEntriesBulkUpdate } from '../exportWizard/composition/billingEntitiesBulkUpdate';

const TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  DEBIT: 'debitAccount',
  CREDIT: 'creditAccount',
  CODE: 'codeAccount',
  ACTIONS: 'actions',
};

const ACTIONS = {
  VAT_ACCOUNT_EDIT: 'vatAccountEdit',
  DEBIT_ACCOUNT_EDIT: 'debitAccountEdit',
  CREDIT_ACCOUNT_EDIT: 'creditAccountEdit',
  ADD_ACCOUNTS: 'addAccounts',
};

export default {
  components: { Table, Button, KebabIcon, PlusIcon, EditBookkeepingAccountModal, AddSupplierAccountsModal },
  props: {
    customer: { type: Object, required: true },
  },
  setup(props) {
    const { suppliers, loading: suppliersLoading } = useCustomerSuppliers();
    const { currentTenantId } = useTenancy();

    const {
      bookkeepingAccounts,
      createOrUpdateBookkeepingAccount,
      deleteBookkeepingAccount,
      loading: bookkeepingAccountsLoading,
    } = useBookkeepingAccountsData(
      computed(() => ({ customerId: props.customer.id, bookkeeperId: currentTenantId.value }))
    );

    const { bulkUpdateBillingEntries } = billingEntriesBulkUpdate();
    const customerSuppliers = computed(() => (bookkeepingAccounts.value ?? []).map(({ supplierId }) => supplierId));

    return {
      TABLE_HEADERS,
      ACTIONS,
      ACCOUNT_TYPES,
      pageSize: 50,
      currentPageIndex: ref(0),
      activeRowActionsIndex: ref(-1),
      currentTenantId,
      suppliers,
      suppliersToAdd: computed(() => suppliers.value?.filter(({ id }) => !customerSuppliers.value.includes(id))),
      accountsGroupedBySupplier: computed(() => groupBy(prop('supplierId'))(bookkeepingAccounts.value)),
      vatBookkeepingAccount: computed(() => bookkeepingAccounts.value.find(({ type }) => type === ACCOUNT_TYPES.VAT)),
      loading: computed(() => suppliersLoading.value || bookkeepingAccountsLoading.value),
      createOrUpdateBookkeepingAccount,
      deleteBookkeepingAccount,
      accountToUpdateType: ref(null),
      accountToUpdateNumber: ref(null),
      accountToUpdateSupplier: ref(null),
      isEditModalOpen: ref(false),
      isAddAccountsModalOpen: ref(false),
      bulkUpdateBillingEntries,
    };
  },
  computed: {
    columns() {
      return [
        { key: TABLE_HEADERS.SUPPLIER, header: this.translate(TABLE_HEADERS.SUPPLIER), width: '400px' },
        { key: TABLE_HEADERS.DEBIT, header: this.translate(TABLE_HEADERS.DEBIT) },
        { key: TABLE_HEADERS.CREDIT, header: this.translate(TABLE_HEADERS.CREDIT) },
        { key: TABLE_HEADERS.CODE, header: this.translate(TABLE_HEADERS.CODE) },
        { key: TABLE_HEADERS.ACTIONS, header: '', width: '40px', customClass: 'p-0' },
      ];
    },
    tableData() {
      return this.loading
        ? []
        : Object.entries(this.accountsGroupedBySupplier)
            .filter(([supplierId]) => supplierId !== 'null')
            .map(([supplierId]) => {
              const supplier = this.suppliers.find(({ id }) => id === supplierId);
              return {
                [TABLE_HEADERS.SUPPLIER]: supplier.name,
                [TABLE_HEADERS.DEBIT]: this.getCurrentAccount(ACCOUNT_TYPES.DEBIT, supplier.id)?.account ?? null,
                [TABLE_HEADERS.CREDIT]: this.getCurrentAccount(ACCOUNT_TYPES.CREDIT, supplier.id)?.account ?? null,
                [TABLE_HEADERS.CODE]: this.getCurrentAccount(ACCOUNT_TYPES.CODE, supplier.id)?.account ?? null,
                supplierData: supplier,
              };
            });
    },
    paginatedData() {
      return this.tableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
  },
  methods: {
    translate(columnKey) {
      return this.$t(`bookkeeper.settings.${columnKey}`);
    },
    accountTypeTranslation(type) {
      return this.$t(`bookkeeping.exportWizard.fields.${type}Account`);
    },
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    closeActions() {
      if (this.activeRowActionsIndex !== -1) {
        document.getElementById(`actions-row-${this.activeRowActionsIndex}`).click();
        this.activeRowActionsIndex = -1;
      }
      if (this.activeBalanceDuePopover !== -1) this.activeBalanceDuePopover = -1;
      this.activeDueDatePopover = -1;
    },
    async editAccount({ type, accountNumber, supplierData }) {
      this.accountToUpdateType = type;
      this.accountToUpdateNumber = accountNumber;
      if (type !== ACCOUNT_TYPES.VAT) this.accountToUpdateSupplier = supplierData;
      this.isEditModalOpen = true;
    },
    getCurrentAccount(type, supplierId) {
      if (type === ACCOUNT_TYPES.VAT) return this.vatBookkeepingAccount;
      return this.accountsGroupedBySupplier[supplierId].find((account) => account.type === type);
    },
    async accountUpdate({ newAccountNumber, override }) {
      const currentAccount = this.accountToUpdateNumber
        ? this.getCurrentAccount(this.accountToUpdateType, this.accountToUpdateSupplier?.id)
        : null;
      await this.createOrUpdateBookkeepingAccount(
        currentAccount,
        this.accountToUpdateType,
        newAccountNumber,
        this.currentTenantId,
        this.accountToUpdateSupplier?.id
      );
      await this.bulkUpdateBillingEntries({
        data: {
          customerId: this.customer.id,
          ...(this.accountToUpdateType !== ACCOUNT_TYPES.VAT && { supplierId: this.accountToUpdateSupplier.id }),
          accountType: this.accountToUpdateType,
          accountNumber: newAccountNumber,
          override,
        },
      });
      this.closeEdit();
    },
    async accountDelete({ override }) {
      const currentAccount = this.accountToUpdateNumber
        ? this.getCurrentAccount(this.accountToUpdateType, this.accountToUpdateSupplier?.id)
        : null;

      if (currentAccount) {
        await this.deleteBookkeepingAccount(currentAccount);
      }

      await this.bulkUpdateBillingEntries({
        data: {
          customerId: this.customer.id,
          ...(this.accountToUpdateType !== ACCOUNT_TYPES.VAT && { supplierId: this.accountToUpdateSupplier.id }),
          accountType: this.accountToUpdateType,
          accountNumber: null,
          override,
        },
      });

      this.closeEdit();
    },
    closeEdit() {
      this.accountToUpdateType = null;
      this.accountToUpdateNumber = null;
      this.accountToUpdateSupplier = null;
      this.isEditModalOpen = false;
    },
    async addSupplierAccounts({ supplierId, debitAccountNumber, creditAccountNumber, codeAccountNumber, override }) {
      await Promise.all([
        this.createOrUpdateBookkeepingAccount(
          null,
          ACCOUNT_TYPES.DEBIT,
          debitAccountNumber,
          this.currentTenantId,
          supplierId
        ),
        this.createOrUpdateBookkeepingAccount(
          null,
          ACCOUNT_TYPES.CREDIT,
          creditAccountNumber,
          this.currentTenantId,
          supplierId
        ),
        this.createOrUpdateBookkeepingAccount(
          null,
          ACCOUNT_TYPES.CODE,
          codeAccountNumber,
          this.currentTenantId,
          supplierId
        ),
      ]);
      await Promise.all([
        this.bulkUpdateBillingEntries({
          data: {
            customerId: this.customer.id,
            supplierId: supplierId,
            accountType: ACCOUNT_TYPES.DEBIT,
            accountNumber: debitAccountNumber,
            override,
          },
        }),
        this.bulkUpdateBillingEntries({
          data: {
            customerId: this.customer.id,
            supplierId: supplierId,
            accountType: ACCOUNT_TYPES.CREDIT,
            accountNumber: creditAccountNumber,
            override,
          },
        }),
        this.bulkUpdateBillingEntries({
          data: {
            customerId: this.customer.id,
            supplierId: supplierId,
            accountType: ACCOUNT_TYPES.CODE,
            accountNumber: codeAccountNumber,
            override,
          },
        }),
      ]);
      this.isAddAccountsModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.bookkeeper-settings {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
  }

  :deep(tr),
  .custom-row {
    height: 2.5rem;
    &:hover,
    &:hover td {
      cursor: default !important;
      background-color: $light-gray;
    }
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}
</style>
